<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          <img :src="require('@/assets/images/logo/logo-main.png')" height="60px" />
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Forgot Password? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your phone and we'll send you verify Code to reset your password
          </b-card-text>
          <b-card-text
            v-if="confirmForm"
            class="mb-2">
            A message verification has been sent to {{ userEmail }}.
            <b-button size="sm" @click="reSendSms" :disabled="!allowResend" variant="info"><feather-icon icon="RefreshCwIcon" size="12" /> resend</b-button>
              <span v-if="countDown > 0">{{ countDown }}</span>
          </b-card-text>
          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                v-if="!confirmForm"
                label="Phone Number"
                label-for="forgot-password-phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  rules="required"
                >
                  <b-input-group
                    class="mb-1"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-input-group-prepend>
                      <b-form-select class="w-80" :options="country_codes" v-model="country_code" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="forgot-password-phone"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="forgot-password-phone"
                      placeholder="0969985009"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div v-if="confirmForm">
                <b-form-group
                  label="Otp code"
                  label-for="forgot-opt-code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="OtpCode"
                    rules="required"
                  >
                    <b-form-input
                      id="forgot-otp-code"
                      v-model="userOtpCode"
                      :state="errors.length > 0 ? false:null"
                      name="forgot-otp-code"
                      placeholder="123456"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ textSubmit }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BCardTitle, BInputGroup, BCardText, BFormSelect, BImg, BForm, BInputGroupPrepend, BFormGroup, BFormInput, BButton
} from 'bootstrap-vue'

import codes from '@core/codes'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store/index'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BInputGroup,
    BFormSelect,
    BInputGroupPrepend,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
  },
  mounted() {
    this.country_codes = codes.map(code => {
      return {
        value: code.dial_code,
        text: code.dial_code,
      }
    })
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      confirmForm: false,
      textSubmit: 'Verify Code',
      userOtpCode: '',
      allowResend: false,
      countDown: 60,
      country_codes: [
        {
          value: '+84', text: '+84',
        },
      ],
      country_code: '+84',
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    rewritePhone(userPhone) {
      let newPhone = userPhone
      if (userPhone[0] === '0') {
        newPhone = userPhone.substring(1)
      }
      return this.country_code + newPhone
    },
    reSendSms(e) {
      e.preventDefault()
      // reconfirm
      this.confirmForm = false
      this.sendResetPassword('/auth/send-sms-reset-password', {
        code: this.userOtpCode,
        phone: this.rewritePhone(this.userEmail),
      }).then(() => {
        this.allowResend = false
        this.startTimer(60)
      })
    },
    startTimer(e = 0) {
      if (e > 0) {
        this.countDown = e
      }
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.startTimer()
        }, 1000)
      } else {
        this.allowResend = true
      }
    },
    sendResetPassword(url, dataQ) {
      return this.$http.post(url, dataQ)
        .then(({ data }) => {
          if (data.result === true) {
            if (this.confirmForm === true) {
              // redirect to change passowrd
              this.$router.push({ name: 'auth-reset-password', params: { token: data.token } })
            } else {
              this.startTimer()
              this.confirmForm = true
            }
            if (this.confirmForm === false) this.confirmForm = true
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Email sent sms to ${this.userEmail}. Please check you phone inbox!`,
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: data.error,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const NewUserEmail = this.rewritePhone(this.userEmail)
          let url = '/auth/send-sms-reset-password'
          let dataQ = { phone: NewUserEmail }

          if (this.confirmForm === true) {
            url = '/auth/confirm-sms-reset-password'
            this.textSubmit = 'Confirm Code'
            dataQ = {
              code: this.userOtpCode,
              phone: NewUserEmail,
            }
            // redirect change pass if true
          }
          ///
          this.sendResetPassword(url, dataQ)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
